import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "@/hooks/useDebounce.ts";
import { useAuthStore } from "@/stores/authStore";
import { trpc } from "@/utils/trpc";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { useResetStores } from "./resetAllStores";

const useAuthCheck = () => {
	const [shouldRedirect, setShouldRedirect] = useState(false);
	const resetAllStores = useResetStores();
	const [searchParams] = useSearchParams();
	const [loading, setLoading] = useState(true);
	const debouncedLoading = useDebounce(loading, 500);
	const {
		lastBrokerPartnershipId,
		setPartnershipId,
		setLastBrokerPartnershipId,
	} = useAuthStore(
		({
			lastBrokerPartnershipId,
			setPartnershipId,
			setLastBrokerPartnershipId,
		}) => ({
			lastBrokerPartnershipId,
			setPartnershipId,
			setLastBrokerPartnershipId,
		}),
	);

	const { mutateAsync } = trpc.brokers.getBroker.useMutation();

	const handleFirstVisit = useCallback(async (partnershipId: string | null) => {
		const { success: isValidPartnershipId } = z
			.string()
			.min(30)
			.safeParse(partnershipId);

		if (partnershipId && isValidPartnershipId) {
			try {
				await mutateAsync({ brokerApiKey: partnershipId });
				setPartnershipId(partnershipId);
				if (partnershipId !== lastBrokerPartnershipId) {
					resetAllStores();
				}
				setLastBrokerPartnershipId(partnershipId);
			} catch (e) {
				setShouldRedirect(true);
			}
		} else {
			setShouldRedirect(true);
		}
		setLoading(false);
	}, []);
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const partnershipId = searchParams.get("partnership_id");

		handleFirstVisit(partnershipId);
	}, [searchParams]);

	useEffect(() => {
		if (shouldRedirect && !debouncedLoading) {
			window.location.href = `${import.meta.env.VITE_PUBLIC_CATALOG_URL}/#/sign-in`;
		}
	}, [debouncedLoading, shouldRedirect]);

	return debouncedLoading;
};

export default useAuthCheck;
