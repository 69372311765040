import { useEffect } from "react";
import { useSendSubscriptionLinkToProspect } from "@/api/useSendSubscriptionLinkToProspect.ts";
import { useSubmitFees } from "@/api/useSubmitFees.ts";
import { useSubscriptionInfoById } from "@/api/useSubscription.ts";
import { CustomSpinner } from "@/components/CustomSpinner.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { useCustomNavigateWithSubID } from "@/hooks/useCustomNavigateWithSubID.ts";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import { TarificationLeftSection } from "@/pages/tarification/TarificationLeftSection.tsx";
import { TarificationRightSection } from "@/pages/tarification/TarificationRightSection.tsx";
import { useAuthStore } from "@/stores/authStore.tsx";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import type { FeesInputDto } from "@repos/cyber-dtos";
import { FeesInputSchema } from "@repos/cyber-dtos";

export const TarificationPage = () => {
	const submitFeesMutation = useSubmitFees();
	const partnershipId = useAuthStore((state) => state.partnershipId);
	const subscriptionId = useSubscriptionIdFromQueryParam();
	const subscriptionDataQuery = useSubscriptionInfoById(subscriptionId);
	const getSubscritionIssuesQuery = trpc.subscriptions.getSubscription.useQuery(
		{
			subscriptionId,
		},
	);
	const sendSubscriptionLinkToProspect = useSendSubscriptionLinkToProspect();
	const navigate = useCustomNavigateWithSubID({
		queryParam: `partnership_id=${partnershipId}`,
	});
	const methods = useForm<FeesInputDto>({
		resolver: zodResolver(FeesInputSchema),
		defaultValues: {
			splittingFees: 0,
		},
	});

	useEffect(() => {
		if (getSubscritionIssuesQuery.isError) {
			toast({
				title: "Erreur",
				description: getSubscritionIssuesQuery.error.message,
				variant: "destructive",
				duration: 20000,
			});
			navigate("TarificationFailed");
		} else if (subscriptionDataQuery.isError) {
			toast({
				title: "Erreur",
				description:
					"Impossible de récupérer les informations de la souscription.",
				variant: "destructive",
			});
			navigate("TarificationFailed");
		}
		if (subscriptionDataQuery.data) {
			methods.setValue(
				"managementFees",
				subscriptionDataQuery.data.managementFees,
			);
			methods.setValue("fileFees", subscriptionDataQuery.data.fileFees);
		}
	}, [subscriptionDataQuery.data, subscriptionDataQuery.isError]);

	const onSubmit = async (data: FeesInputDto) => {
		try {
			await submitFeesMutation.mutateAsync({ subscriptionId, fees: data });
		} catch (e) {
			toast({
				title: "Erreur",
				description:
					"Une erreur est survenue lors de l'envoi des frais, veuillez réessayer.",
				variant: "destructive",
			});
			return;
		}
		try {
			await sendSubscriptionLinkToProspect.mutateAsync({ subscriptionId });
		} catch (e) {
			toast({
				title: "Erreur",
				description:
					"Une erreur est survenue lors de l'envoi de l'email, veuillez réessayer",
				variant: "destructive",
			});
			return;
		}
		navigate("TarificationSuccess");
	};
	if (subscriptionDataQuery.isLoading) {
		return <CustomSpinner />;
	}
	return (
		<FormProvider {...methods}>
			<form
				className="flex h-full w-full flex-col gap-8 lg:flex-row lg:gap-0 lg:p-12"
				onSubmit={methods.handleSubmit(onSubmit)}
			>
				<TarificationLeftSection
					data={subscriptionDataQuery.data}
					className="p-6 lg:absolute lg:left-0 lg:top-20 lg:w-1/2 lg:p-12"
				/>
				<TarificationRightSection className="h-full lg:absolute lg:bottom-0 lg:right-0 lg:top-0 lg:w-1/2" />
			</form>
		</FormProvider>
	);
};
