import type { ReactElement } from "react";
import errorIcon from "@/assets/ErrorIcon.png";
import logo from "@/assets/logo.png";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";

export const PageWithSubIdQueryParam = ({
	children,
}: {
	children: ReactElement;
}) => {
	const subscriptionId = useSubscriptionIdFromQueryParam();
	if (!subscriptionId) {
		return (
			<div className="h-screen">
				<div className="absolute left-0 top-0 w-full px-10 py-5  ">
					<h1 className="text-2xl font-bold text-[#f70004] ">
						Assurance CyberClear by As Solutions
					</h1>
					<img
						src={logo}
						alt="Logo"
						className=" fixed right-10 top-0 z-10 w-32"
					/>
				</div>
				<main className="flex h-full items-center justify-center py-9">
					<div className="flex h-full w-full flex-col items-center justify-center gap-10 ">
						<div className="flex items-center justify-center gap-6 text-[#f70004]">
							<img src={errorIcon} alt="error" className="w-28" />
							<h1 className="text-[100px] font-bold">OOPS</h1>
						</div>
						<p className=" text-center text-2xl font-bold">
							Aucune souscription trouvée
						</p>
					</div>
				</main>
			</div>
		);
	}
	return children;
};
