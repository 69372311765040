import { useSubscriptionInfoById } from "@/api/useSubscription.ts";
import emailIcon from "@/assets/emailIcon.png";
import { PaymentSummary } from "@/components/PaymentSummary/PaymentSummary";
import { ResumeItemDisplay } from "@/components/ResumeItemDisplay.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import { useAuthStore } from "@/stores/authStore.tsx";
import { cn, trpc } from "@/utils";
import { Loader2, MailCheck } from "lucide-react";
import { useFormContext, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import type { FeesInputDto } from "@repos/cyber-dtos";

interface TarificationRightSectionProps
	extends React.HTMLAttributes<HTMLDivElement> {}

export const TarificationRightSection = ({
	className,
}: TarificationRightSectionProps) => {
	const subscriptionId = useSubscriptionIdFromQueryParam();
	const navigate = useNavigate();
	const partnershipId = useAuthStore((state) => state.partnershipId);
	const subscriptionDataQuery = useSubscriptionInfoById(subscriptionId);
	const { control } = useFormContext<FeesInputDto>();
	const managmentFees = useWatch({ name: "managementFees", control });
	const fileFees = useWatch({ name: "fileFees", control });

	const handleNavigateToWelcomePage = () => {
		navigate(`/?partnership_id=${partnershipId}`);
	};
	const sendDevisEmailToProspectMutation =
		trpc.mailing.sendEmailToSubscriber.useMutation({
			onSuccess: () => {
				toast({
					title: "Succès",
					description: "L'email de devis a été envoyé avec succès.",
					variant: "success",
				});
			},
			onError: () => {
				toast({
					title: "Erreur",
					description:
						"Une erreur s'est produite lors de l'envoi de l'email. Veuillez réessayer.",
					variant: "destructive",
				});
			},
		});
	const handleSendDevisEmailToProspect = () => {
		if (sendDevisEmailToProspectMutation.isLoading) return;
		if (sendDevisEmailToProspectMutation.isSuccess) {
			toast({
				title: "Succès",
				description: "L'email est déjà envoyé.",
				variant: "success",
			});
			return;
		}
		sendDevisEmailToProspectMutation.mutate({ subscriptionId });
	};
	return (
		<PaymentSummary.Root className={cn(className)}>
			<PaymentSummary.Content>
				<ul className="flex w-full flex-col">
					<ResumeItemDisplay
						className={"py-0 pb-6 "}
						title="Date d'effet"
						value={subscriptionDataQuery.data?.desiredEffectiveDate}
						isLoading={subscriptionDataQuery.isLoading}
						type="date"
					/>

					<ResumeItemDisplay
						title="Prime/mois"
						value={subscriptionDataQuery.data?.monthlyPremiumPIT}
						isLoading={subscriptionDataQuery.isLoading}
					/>

					<ResumeItemDisplay
						title="Prime/an"
						value={subscriptionDataQuery.data?.annualPremiumPIT}
						isLoading={subscriptionDataQuery.isLoading}
					/>

					<ResumeItemDisplay
						title="Frais de gestion"
						value={managmentFees}
						isLoading={subscriptionDataQuery.isLoading}
					/>
					<ResumeItemDisplay
						title="Frais de dossier"
						value={fileFees}
						isLoading={subscriptionDataQuery.isLoading}
					/>
				</ul>
			</PaymentSummary.Content>
			<PaymentSummary.Footer className="gap-3">
				<Button
					type="button"
					className="gap-2bg-primary flex w-full px-12 py-6"
					onClick={handleSendDevisEmailToProspect}
				>
					<div className="icon mr-2 text-white">
						{sendDevisEmailToProspectMutation.isLoading ? (
							<Loader2 className="h-5 w-5 animate-spin" />
						) : sendDevisEmailToProspectMutation.isSuccess ? (
							<MailCheck className="h-5 w-5" />
						) : (
							<img src={emailIcon} alt="email" className="fill-amber-50" />
						)}
					</div>

					<span
						className={cn(" text-white underline ", {
							"opacity-80":
								sendDevisEmailToProspectMutation.isLoading ||
								sendDevisEmailToProspectMutation.isSuccess,
						})}
					>
						Cliquez ici pour envoyer le devis au prospect
					</span>
				</Button>
				<TooltipProvider>
					<Tooltip delayDuration={200}>
						<TooltipTrigger className="w-full" type="button">
							<Button
								type="submit"
								variant="secondary"
								className="disabled:bg-primary-100 flex
					w-full gap-4 px-12 py-6
					underline
					disabled:border
					disabled:border-gray-400
					disabled:opacity-60
					"
								disabled={!sendDevisEmailToProspectMutation.isSuccess}
							>
								Envoyer un lien de souscription à votre client
							</Button>
						</TooltipTrigger>
						<TooltipContent side="bottom">
							<p>
								L&apos;envoi du devis est obligatoire avant d&apos;envoyer le
								lien de souscription.
							</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
				<Button
					type="button"
					variant="outline"
					className="flex w-full gap-4  px-12 py-6"
					onClick={handleNavigateToWelcomePage}
				>
					Souscrire à nouveau
				</Button>
			</PaymentSummary.Footer>
		</PaymentSummary.Root>
	);
};
