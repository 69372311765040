import { useState } from "react";
import { CheckboxWithLabel } from "@/components/CheckboxWithLabel.tsx";
import { TextInput } from "@/components/TextInput.tsx";
import { cn } from "@/utils";
import { useFormContext } from "react-hook-form";

import type { FeesInputDto } from "@repos/cyber-dtos";

interface TarificationLeftSectionProps
	extends React.HTMLAttributes<HTMLDivElement> {
	data?: FeesInputDto;
}

export const TarificationLeftSection = ({
	data,
	className,
}: TarificationLeftSectionProps) => {
	const [fractionalPaymentChecked, setFractionalPaymentChecked] =
		useState(false);
	const { setValue } = useFormContext<FeesInputDto>();
	return (
		<div className={cn("flex flex-col gap-6", className)}>
			<div className={cn("flex flex-col justify-center gap-4 lg:gap-12")}>
				<div className="text-2xl font-bold text-primary ">
					Informations complémentaires
				</div>
				<div className="flex w-full flex-col gap-4 md:flex-row ">
					<TextInput<FeesInputDto>
						type="number"
						label={"Frais de gestion *"}
						name={"managementFees"}
						helperText="Frais de gestion sera payé une fois chaque mois"
						endAdornment="€"
						disabled
					/>
					<TextInput<FeesInputDto>
						type="number"
						label={"Frais de dossier *"}
						name={"fileFees"}
						helperText="Frais de dossier sera payé une fois quant à la souscription"
						endAdornment="€"
						disabled
					/>
				</div>
			</div>
			<div className="flex flex-col gap-4 lg:gap-12">
				<CheckboxWithLabel
					id="fractionalPayment"
					label="En cas de paiement mensuel, je souhaite ajouter les frais de fractionnement"
					onChange={() => {
						setFractionalPaymentChecked(!fractionalPaymentChecked);
						if (!fractionalPaymentChecked)
							setValue("splittingFees", data?.splittingFees || 0);
						else setValue("splittingFees", 0);
					}}
				/>
				{fractionalPaymentChecked && (
					<div className="flex w-full flex-col self-start md:w-1/2">
						<TextInput<FeesInputDto>
							type="number"
							label={"Frais de fractionnement *"}
							name={"splittingFees"}
							endAdornment="€"
							disabled
						/>
					</div>
				)}
			</div>
		</div>
	);
};
