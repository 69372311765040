import { useEffect } from "react";
import { useSubscriptionInfoById } from "@/api/useSubscription.ts";
import { PaymentSummary } from "@/components/PaymentSummary/PaymentSummary.tsx";
import { ResumeItemDisplay } from "@/components/ResumeItemDisplay.tsx";
import { Button } from "@/components/ui/button.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { useCustomNavigateWithSubID } from "@/hooks/useCustomNavigateWithSubID.ts";
import { useSubscriptionIdFromQueryParam } from "@/hooks/useSubscriptionIdFromQueryParam.ts";
import { ContractualDocsButtonAndModal } from "@/pages/paymentSelect/ContractualDocsButtonAndModal.tsx";
import { useStore } from "@/stores/useStore.ts";

interface PaymentSelectRightSectionProps
	extends React.HTMLAttributes<HTMLDivElement> {}

export const PaymentSelectRightSection = ({
	className,
}: PaymentSelectRightSectionProps) => {
	const subscriptionId = useSubscriptionIdFromQueryParam();
	const subscriptionDataQuery = useSubscriptionInfoById(subscriptionId);
	const navigate = useCustomNavigateWithSubID({});

	useEffect(() => {
		if (subscriptionDataQuery.isError) {
			toast({
				title: "Erreur",
				description:
					"Impossible de récupérer les informations de la souscription.",
				variant: "destructive",
			});
		}
	}, [subscriptionDataQuery.isError]);
	const { paymentMethod, periodicity } = useStore((state) => state);
	const onSubmit = () => {
		if (periodicity) {
			switch (paymentMethod) {
				case "SEPA":
					navigate("PaymentSEPA");
					break;
				case "CB":
					navigate("PaymentCB");
					break;
				default:
					break;
			}
		} else {
			toast({
				title: "Erreur",
				description: "Veuillez sélectionner une périodicité de paiement.",
				variant: "destructive",
			});
		}
	};

	return (
		<PaymentSummary.Root className={className}>
			<PaymentSummary.Content>
				<ul className="flex w-full flex-col">
					<ResumeItemDisplay
						className={"py-0 pb-6 "}
						title="Date d'effet"
						value={subscriptionDataQuery.data?.desiredEffectiveDate}
						isLoading={subscriptionDataQuery.isLoading}
						type="date"
					/>

					<ResumeItemDisplay
						title="Frais de gestion"
						value={subscriptionDataQuery.data?.managementFees}
						isLoading={subscriptionDataQuery.isLoading}
						informationTooltipContent="Couvrent les coûts administratifs liés à la gestion de la police d’assurance."
					/>
					<ResumeItemDisplay
						title="Frais de dossier"
						value={subscriptionDataQuery.data?.fileFees}
						isLoading={subscriptionDataQuery.isLoading}
						informationTooltipContent="Prélevés lors de la première souscription et couvrent les coûts administratifs liés à la création de la police d’assurance."
					/>
				</ul>
			</PaymentSummary.Content>
			<PaymentSummary.Footer className="gap-6">
				<ContractualDocsButtonAndModal />
				<div className="flex w-full items-center justify-center gap-4">
					<Button className="rounded-xl px-6 py-3" onClick={onSubmit}>
						Confirmer
					</Button>
				</div>
			</PaymentSummary.Footer>
		</PaymentSummary.Root>
	);
};
